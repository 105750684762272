import { LobbyItem, LobbyItemType } from '@scpc/dto/lobby';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';

export function applyUpdateAndGetDraws(item: LobbyItem, draws: Draw[], draw: Draw): Draw[] {
  const index = draws.findIndex((i: Draw) => i.drawId === draw.drawId);
  if (index >= 0) {
    draws[index] = draw;
  } else if (item.type === LobbyItemType.LUCKY_NUMBERS_POPULAR_ITEM && item.drawsIds.includes(draw.drawId)) {
    draws.push(draw);
  } else if (item.type === LobbyItemType.LUCKY_NUMBERS_RAPID_ITEM && draw.type === 'INSTANT') {
    draws.push(draw);
  }
  return filterDraws(item, draws);
}

export function filterDraws(item: LobbyItem, draws: Draw[]): Draw[] {
  const now: number = Date.now() + 1000;
  return draws
    .filter(d => d.active && d.drawDate > now)
    .sort((a: Draw, b: Draw) => getSortDiff(a, b))
    .slice(0, item.length);
}

export function getSortDiff(a: Draw, b: Draw): number {
  const aV = a.drawDate;
  const bV = b.drawDate;
  if (aV === bV) {
    return a.shortDrawName.localeCompare(b.shortDrawName);
  }
  return aV > bV ? 1 : -1;
}
