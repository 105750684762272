import { Routes } from '@angular/router';
import { PageResolver } from '@scpc/modules/common/resolvers/page.resolver';
import { LobbyResolver } from '@scpc/modules/lobby/resolvers/lobby.resolver';

export const ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('@scpc/modules/lobby/pages/lobby/lobby.component').then(c => c.LobbyComponent),
    data: {
      fullWidth: true,
      hasFooter: true,
      smartBanner: true,
    },
    resolve: { seo: PageResolver, lobby: LobbyResolver },
  },
];
