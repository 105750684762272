<div class='scp-lobby' [class.scp-lobby__margin]='!isAuthorized'>
  @for (item of lobby; track trackLobbyByFn($index, item)) {
    @switch (item.type) {
      @case ('BONUSES_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [onlyForAuthorized]='true'
                        [beginning]='component.isBeginning() | async'
                        [end]='component.isEnd() | async'
                        (prev)='component.prev()'
                        (next)='component.next()'>
          <scp-lobby-item-bonuses #component
                                  [offers]='item.bonuses'
                                  [breakpoint]='item.breakpoint'>
          </scp-lobby-item-bonuses>
        </scp-lobby-item>
      }
      @case ('RECOMMENDED_GAMES_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [onlyForAuthorized]='false'
                        [beginning]='component.isBeginning() | async'
                        [end]='component.isEnd() | async'
                        (prev)='component.prev()'
                        (next)='component.next()'>
          <scp-games-swiper #component [games]='item.games' [currency]='currency'></scp-games-swiper>
        </scp-lobby-item>
      }
      @case ('TOP_WINNERS_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'>
          <scp-top-winners [class.authorized]='isAuthorized'></scp-top-winners>
        </scp-lobby-item>
      }
      @case ('RECENTLY_PLAYED_GAMES_ITEM') {
        @if (isAuthorized) {
          <scp-lobby-item [isAuthorized]='isAuthorized'
                          [title]='item.title'
                          [link]='item.link'
                          [class]='item.type'
                          [onlyForAuthorized]='true'
                          [beginning]='component.isBeginning() | async'
                          [end]='component.isEnd() | async'
                          (prev)='component.prev()'
                          (next)='component.next()'>
            <scp-games-swiper #component [games]='item.games' [currency]='currency'></scp-games-swiper>
          </scp-lobby-item>
        }
      }
      @case ('BANNERS_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'>
          <scp-banner [slides]='item.banners'
                      [isAuthorized]='isAuthorized'
                      [isFTD]='(storageService.getCustomer() | async)?.isFTD || false'>
          </scp-banner>
        </scp-lobby-item>
      }
      @case ('LIVE_GAME_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [navigation]='false'>
          <scp-game-icon
            [ngStyle]="{ 'padding-bottom': 'calc(' + item.games[0].icon.height + ' / ' + item.games[0].icon.width + ' * 100%)' }"
            [game]='item.games[0]'
            [currency]='currency'
            [simple]='true'>
          </scp-game-icon>
        </scp-lobby-item>
      }
      @case ('LIVE_GAMES_CATEGORY_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [navigation]='false'>
          <a [routerLink]='item.category.url' class='games-category'
             aria-label='Navigate to the casino games category'
             [ngStyle]="{ 'padding-bottom': 'calc(' + item.category.image.height + ' / ' + item.category.image.width + ' * 100%)' }">
            <img [src]='item.category.image.url' alt='' loading='lazy'>
          </a>
        </scp-lobby-item>
      }
      @case ('SLOT_GAME_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [navigation]='false'>
          <scp-game-icon
            [ngStyle]="{ 'padding-bottom': 'calc(' + item.games[0].icon.height + ' / ' + item.games[0].icon.width + ' * 100%)' }"
            [game]='item.games[0]'
            [currency]='currency'
            [simple]='true'>
          </scp-game-icon>
        </scp-lobby-item>
      }
      @case ('SLOT_GAMES_CATEGORY_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [navigation]='false'>
          <a [routerLink]='item.category.url' class='games-category'
             aria-label='Navigate to the slots games category'
             [ngStyle]="{ 'padding-bottom': 'calc(' + item.category.image.height + ' / ' + item.category.image.width + ' * 100%)' }">
            <img [src]='item.category.image.url' alt='' loading='lazy'>
          </a>
        </scp-lobby-item>
      }
      @case ('LIVE_GAMES_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [beginning]='component.isBeginning() | async'
                        [end]='component.isEnd() | async'
                        (prev)='component.prev()'
                        (next)='component.next()'>
          <scp-games-swiper #component
                            [games]='item.games'
                            [currency]='currency'
                            [responsive]='true'
                            [itemsOnMobile]='item.itemsOnMobile'
                            [slug]='item.link.url.split("/").reverse()[0]'>
          </scp-games-swiper>
        </scp-lobby-item>
      }
      @case ('SLOT_GAMES_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [beginning]='component.isBeginning() | async'
                        [end]='component.isEnd() | async'
                        (prev)='component.prev()'
                        (next)='component.next()'>
          <scp-games-swiper #component [games]='item.games'
                            [currency]='currency'
                            [responsive]='true'
                            [itemsOnMobile]='item.itemsOnMobile'
                            [slug]='item.link.url.split("/").reverse()[0]'></scp-games-swiper>
        </scp-lobby-item>
      }
      @case ('SPORT_EVENTS_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [beginning]='component.isBeginning() | async'
                        [end]='component.isEnd() | async'
                        (prev)='component.prev()'
                        (next)='component.next()'>
          <scp-lobby-item-sport-events #component
                                       [events]='item.events'
                                       [itemsOnMobile]='item.itemsOnMobile'
                                       (empty)='remove(item)'>
          </scp-lobby-item-sport-events>
        </scp-lobby-item>
      }
      @case ('LUCKY_NUMBERS_POPULAR_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [beginning]='component.isBeginning() | async'
                        [end]='component.isEnd() | async'
                        (prev)='component.prev()'
                        (next)='component.next()'>
          <scp-lobby-item-lucky-numbers-draws #component
                                              [draws]='item.draws'
                                              [itemsOnMobile]='item.itemsOnMobile'
                                              (reload)='refreshDraws(item)'>
          </scp-lobby-item-lucky-numbers-draws>
        </scp-lobby-item>
      }
      @case ('LUCKY_NUMBERS_RAPID_ITEM') {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [beginning]='component.isBeginning() | async'
                        [end]='component.isEnd() | async'
                        (prev)='component.prev()'
                        (next)='component.next()'>
          <scp-lobby-item-lucky-numbers-draws #component
                                              [draws]='item.draws'
                                              [itemsOnMobile]='item.itemsOnMobile'
                                              (reload)='refreshDraws(item)'>
          </scp-lobby-item-lucky-numbers-draws>
        </scp-lobby-item>
      }
    }
  }
</div>
