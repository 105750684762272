@if ((onlyForAuthorized && isAuthorized) || !onlyForAuthorized) {
  @if (title || link) {
    <div class='scp-lobby-item'>
      @if (title) {
        <h2 class='scp-lobby-item__title' [class.full]='!link'>{{ title }}</h2>
      }
      <div style='display: flex'>
        @if (navigation) {
          <button class='scp-lobby-item__navigation'
                  (click)='prev.next()'
                  [disabled]='beginning'
                  [attr.aria-label]='"Click here to view previous items"'>
            <img src='/assets/images/svg/arrow-4.svg' [width]='24' [height]='24' alt=''>
          </button>
          <button class='scp-lobby-item__navigation'
                  (click)='next.emit()'
                  [disabled]='end'
                  [attr.aria-label]='"Click here to view next items"'>
            <img src='/assets/images/svg/arrow-4.svg' [width]='24' [height]='24' alt=''
                 style='transform: rotate(180deg)'>
          </button>
        }
        @if (link) {
          <a class='scp-lobby-item__link' [routerLink]='routeLink' [queryParams]='queryParams'>
            {{ link.title }}
          </a>
        }
      </div>
    </div>
  }
  <ng-content></ng-content>
}
