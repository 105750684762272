import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'competitorImage',
})
export class CompetitorImagePipe implements PipeTransform {

  public transform(id: string) {
    return `/assets/common/sports/competitors/${id.replace(/:/g, '-')}.png`;
  }

}

