import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LiveGame } from '@scpc/dto/live-game';
import { SlotGame } from '@scpc/dto/slot-game';

@Pipe({
  name: 'formatGameUrl',
  pure: true,
})
export class FormatGameUrlPipe implements PipeTransform {

  public transform(searchResultItem: { game?: LiveGame, slot?: SlotGame },
                   activatedRoute: ActivatedRoute,
                   force: boolean = false): {
    routerLink: string,
    queryParams?: Params
  } {
    if (searchResultItem.game) {
      const { gameId, slug, page, provider } = searchResultItem.game;
      const normalizedSlug: string = slug || gameId;
      if (provider.type.startsWith('BET_GAMES') && !page) {
        const category = activatedRoute.snapshot.params.id || /* istanbul ignore next */ '';
        return { routerLink: `/live-games/${normalizedSlug}/launch`, queryParams: { category } };
      }
      return { routerLink: `/live-games/${normalizedSlug}${!page || force ? '/launch' : ''}` };
    }
    const { gameId, slug, page } = searchResultItem.slot;
    const normalizedSlug: string = slug || gameId;
    return { routerLink: `/slots/${normalizedSlug}${!page || force ? '/launch' : ''}` };
  }

}

