<div class='scp-lucky-numbers-draws-swiper'>
  <div class='swiper'>
    <div class='swiper-wrapper'>
      @for (draw of draws; track trackDrawByFn(draw); ) {
        <div class='swiper-slide'
             [class.swiper-hidden-slide]='$index >= itemsOnMobile'>
          <scp-draw-item source='LOBBY'
                         [draw]='draw'
                         (active)='$event === false ? reload.next(): null'>
          </scp-draw-item>
        </div>
      }
    </div>
  </div>
</div>
