import { Directive, ElementRef, Input, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[scpImageFallback]',
})
export class ImgFallbackDirective implements OnDestroy {

  @Input('scpImageFallback')
  public fallback: string;
  @Output()
  public loaded = new EventEmitter<boolean>();
  private readonly nativeElement: HTMLElement;
  private readonly ERROR_EVENT_TYPE: string = 'error';
  private readonly LOAD_EVENT_TYPE: string = 'load';
  private isApplied = false;
  private cancelOnError!: () => void;
  private cancelOnLoad!: () => void;

  constructor(private readonly elementRef: ElementRef,
              private readonly renderer: Renderer2) {
    this.nativeElement = elementRef.nativeElement;
    this.nativeElement.style.opacity = '0';

    this.onError = this.onError.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.addEvents();
  }

  public ngOnDestroy() {
    this.removeErrorEvent();
    this.removeOnLoadEvent();
  }

  private onError() {
    if (this.nativeElement.getAttribute('src') !== this.fallback) {
      this.isApplied = true;
      this.renderer.setAttribute(this.nativeElement, 'src', this.fallback);
    } else {
      this.removeOnLoadEvent();
    }
  }

  /* istanbul ignore next */
  private onLoad() {
    this.nativeElement.style.opacity = '1';
    this.loaded.emit(this.isApplied);
  }

  private removeErrorEvent() {
    if (this.cancelOnError) {
      this.cancelOnError();
    }
  }

  private removeOnLoadEvent() {
    if (this.cancelOnLoad) {
      this.cancelOnLoad();
    }
  }

  private addEvents() {
    this.cancelOnError = this.renderer.listen(this.nativeElement, this.ERROR_EVENT_TYPE, this.onError);
    this.cancelOnLoad = this.renderer.listen(this.nativeElement, this.LOAD_EVENT_TYPE, this.onLoad);
  }

}
