import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Swiper } from 'swiper';
import { FreeMode, Grid } from 'swiper/modules';
import { catchError } from '@scpc/utils/dom.utils';
import { Game } from '@scpc/modules/games-lobby/model';
import { GameIconComponent } from '@scpc/modules/games-lobby/components/game-icon/game-icon.component';
import { AbstractSwiperComponent } from '@scpc/modules/lobby/components/abstract.swiper.component';

@Component({
  selector: 'scp-games-swiper',
  templateUrl: './games-swiper.component.html',
  styleUrls: ['./games-swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [GameIconComponent],
})
export class GamesSwiperComponent<T extends Game> extends AbstractSwiperComponent implements AfterContentInit, OnChanges, OnDestroy {

  @Input()
  public games: T[];

  @Input()
  public slug: string;

  @Input()
  public currency: string;

  @Input()
  public placeholder: boolean;

  @Input()
  public responsive: boolean = false;

  @Input()
  public itemsOnMobile: number = Number.MAX_VALUE;

  constructor(private readonly elementRef: ElementRef<HTMLDivElement>) {
    super();
  }

  public ngAfterContentInit(): void {
    this.idle.requestIdleCallback((): void => {
      this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('.swiper') as HTMLElement, {
        init: this.isBrowser,
        modules: [FreeMode, Grid],
        freeMode: true,
        touchRatio: 1.5,
        speed: 400,
        resistance: true,
        resistanceRatio: 0.5,
        slidesOffsetBefore: 15,
        slidesOffsetAfter: 15,
        slidesPerView: 'auto',
        resizeObserver: true,
        updateOnWindowResize: true,
        watchOverflow: true,
        breakpoints: this.responsive ? {
          '0': {
            slidesPerView: 2,
            slidesPerGroup: 2,
            grid: {
              rows: 100,
              fill: 'row',
            },
            spaceBetween: 10,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          '441': {
            slidesPerView: 'auto',
            grid: undefined,
            spaceBetween: 0,
            slidesOffsetBefore: 15,
            slidesOffsetAfter: 15,
          },
        } : undefined,
        on: { ...this.on },
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('placeholder' in changes && this.swiper) {
      this.swiper.slideTo(0, 0);
    }
  }

  public ngOnDestroy(): void {
    catchError(() => this.swiper.destroy());
  }

}
