<div class='scp-games-swiper' [class.responsive]='responsive'>
  <div class='swiper'>
    <div class='swiper-wrapper'>
      @for (game of games; track game.gameId; ) {
        <div class='swiper-slide'
             [class.swiper-hidden-slide]='$index >= itemsOnMobile'>
          <scp-game-icon [game]='game'
                         [small]='true'
                         [currency]='currency'
                         [category]='slug'
                         [placeholder]='placeholder'>
          </scp-game-icon>
        </div>
      }
    </div>
  </div>
</div>
