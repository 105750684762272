import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { FormatGameUrlPipe } from '@scpc/modules/games-lobby/pipes/format-game-url.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractGameComponent } from '@scpc/modules/games-lobby/components/abstract-game.component';
import { Game } from '@scpc/modules/games-lobby/model';

@Component({
  selector: 'scp-game-icon',
  templateUrl: './game-icon.component.html',
  styleUrls: ['./game-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    TranslateModule,
  ],
  providers: [
    FormatGameUrlPipe,
  ],
})
export class GameIconComponent<T extends Game> extends AbstractGameComponent<T> implements OnInit, OnDestroy {

  @Input()
  public placeholder: boolean = false;

  @Input()
  public small: boolean = false;

  @Input()
  public simple: boolean = false;

  @HostBinding('class.simple')
  protected simpleClass: boolean = false;

  public ngOnInit(): void {
    if (!this.placeholder) {
      this.prepareData();
      this.init();
    }
  }

  protected prepareData(): void {
    super.prepareData();
    this.simpleClass = this.simple;
  }

}
