import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { EventRowComponent } from '@scpc/modules/sports/components/event-row/event-row.component';
import { Event } from '@scpc/modules/sports/dto';
import { SportsWebsocketService } from '@scpc/modules/sports/services/sports.websocket.service';
import { isEventEnded } from '@scpc/modules/sports/utils/event';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Swiper } from 'swiper';
import { FreeMode, Grid } from 'swiper/modules';
import { AbstractSwiperComponent } from '@scpc/modules/lobby/components/abstract.swiper.component';

@Component({
  selector: 'scp-lobby-item-sport-events',
  templateUrl: './lobby-item-sport-events.component.html',
  styleUrls: ['./lobby-item-sport-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EventRowComponent],
})
export class LobbyItemSportEventsComponent extends AbstractSwiperComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input()
  public events: Event[];

  @Input()
  public itemsOnMobile: number = Number.MAX_VALUE;

  @Output()
  public empty: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren(EventRowComponent)
  private rowsComponents: QueryList<EventRowComponent>;

  protected map: Map<string, Event> = new Map<string, Event>();

  constructor(private readonly sportsWebsocketService: SportsWebsocketService,
              private readonly elementRef: ElementRef<HTMLDivElement>) {
    super();
  }

  public ngOnInit(): void {
    for (const event of this.events) {
      this.map.set(event.id, event);
    }
  }

  public ngAfterContentInit(): void {
    if (this.isBrowser) {
      this.sportsWebsocketService.onConnect()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.sportsWebsocketService.on('short_event', this.processEventUpdates);
          this.sportsWebsocketService.subscribe(this.events.map(e => `s_e_${e.id}`));
        });
      this.sportsWebsocketService.connect();
    }

    this.idle.requestIdleCallback((): void => {
      this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('.swiper') as HTMLElement, {
        init: this.isBrowser,
        modules: [FreeMode, Grid],
        freeMode: true,
        touchRatio: 1.5,
        speed: 400,
        resistance: true,
        resistanceRatio: 0.5,
        slidesOffsetBefore: 15,
        slidesOffsetAfter: 15,
        slidesPerView: 'auto',
        resizeObserver: true,
        updateOnWindowResize: true,
        watchOverflow: true,
        breakpoints: {
          '0': {
            slidesPerView: 1,
            slidesPerGroup: 1,
            grid: {
              rows: 100,
              fill: 'row',
            },
            spaceBetween: 0,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          '441': {
            slidesPerView: 'auto',
            grid: undefined,
            spaceBetween: 0,
            slidesOffsetBefore: 15,
            slidesOffsetAfter: 15,
          },
        },
        on: { ...this.on },
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.isBrowser) {
      this.sportsWebsocketService.unsubscribe(this.events.map(e => `s_e_${e.id}`));
    }
  }

  private processEventUpdates = (data: string): void => {
    this.zone.runOutsideAngular(() => {
      const event = JSON.parse(data);
      const id = event.id;
      if (this.map.has(event.id)) {
        if (isEventEnded(event)) {
          this.map.delete(event.id);
          if (this.map.size === 0) {
            this.empty.emit();
          }
          this.zone.run(() => this.changeDetectorRef.markForCheck());
        } else {
          this.map.set(event.id, event);
          this.rowsComponents.find(item => item.event.id === id).update();
        }
      }
    });
  };

}
