import { Game, GamesCategory } from '@scpc/modules/games-lobby/model';
import { Banner } from '@scpc/modules/common/components/banners/models';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { Event } from '@scpc/modules/sports/dto';
import { SimpleBonusProgramOffer } from '@scpc/dto/bonus-program-offer';

export enum LobbyItemType {
  RECOMMENDED_GAMES_ITEM = 'RECOMMENDED_GAMES_ITEM',
  BANNERS_ITEM = 'BANNERS_ITEM',
  SEARCH_ITEM = 'SEARCH_ITEM',
  GAMES_CATEGORIES_ITEM = 'GAMES_CATEGORIES_ITEM',
  GAMES_CATEGORY_ITEM = 'GAMES_CATEGORY_ITEM',
  BONUSES_ITEM = 'BONUSES_ITEM',
  RECENTLY_PLAYED_GAMES_ITEM = 'RECENTLY_PLAYED_GAMES_ITEM',
  LIVE_GAME_ITEM = 'LIVE_GAME_ITEM',
  LIVE_GAMES_ITEM = 'LIVE_GAMES_ITEM',
  LIVE_GAMES_CATEGORY_ITEM = 'LIVE_GAMES_CATEGORY_ITEM',
  SLOT_GAME_ITEM = 'SLOT_GAME_ITEM',
  SLOT_GAMES_ITEM = 'SLOT_GAMES_ITEM',
  SLOT_GAMES_CATEGORY_ITEM = 'SLOT_GAMES_CATEGORY_ITEM',
  LUCKY_NUMBERS_POPULAR_ITEM = 'LUCKY_NUMBERS_POPULAR_ITEM',
  LUCKY_NUMBERS_RAPID_ITEM = 'LUCKY_NUMBERS_RAPID_ITEM',
  SPORT_EVENTS = 'SPORT_EVENTS',
  TOP_WINNERS_ITEM = 'TOP_WINNERS_ITEM',
  OUTLET_ITEM = 'OUTLET_ITEM',
  SPORT_EVENTS_ITEM = 'SPORT_EVENTS_ITEM'
}


export interface LobbyItem {
  type: LobbyItemType,
  title?: string,
  link?: {
    title: string,
    url: string,
    product?: string
  },
  length?: number;
  drawsIds?: string[],
  draws?: Draw[],
  games?: Game[],
  events?: Event[];
  banners?: Banner[],
  bonuses?: SimpleBonusProgramOffer[],
  breakpoint?: number;
  itemsOnMobile?: number;
  category?: {
    url: string,
    image: {
      url: string,
      width: number,
      height: number,
    },
  },
  categories?: GamesCategory<Game>[];
}
