import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { FormatGameUrlPipe } from '@scpc/modules/games-lobby/pipes/format-game-url.pipe';
import { ActivatedRoute, Params } from '@angular/router';
import { ScpWebSocketService } from '@scpc/modules/common/services/scp.websocket.service';
import { Game } from '@scpc/modules/games-lobby/model';
import { formatMoney } from '@scpc/utils/money.utils';
import { getNumberOfCurrencyDigits } from '@angular/common';

@Directive()
export class AbstractGameComponent<T extends Game> implements OnDestroy {

  @Input()
  public game: T;

  @Input()
  public currency: string;

  @Input()
  public category: string;

  @Output()
  public gameChange: EventEmitter<T> = new EventEmitter<T>();

  protected subscribed: boolean = false;
  protected topic: string;
  protected event: string;

  protected gameUrlPipe: FormatGameUrlPipe = inject(FormatGameUrlPipe);
  protected route: ActivatedRoute = inject(ActivatedRoute);
  protected scpWebSocketService: ScpWebSocketService = inject(ScpWebSocketService);
  protected zone: NgZone = inject(NgZone);
  protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  protected stake: string | undefined = undefined;
  protected icon: string;
  protected provider: string;
  protected providerIcon: string;
  protected providerIcon2: string;
  protected routeLink: string;
  protected queryParams: Params;
  protected rel: string | null;

  protected static getSlug(product: string): string {
    switch (product) {
      case 'SLOTS':
        return 'slots';
      default:
        return 'live-games';
    }
  }

  public ngOnDestroy(): void {
    if (this.subscribed) {
      this.scpWebSocketService.unsubscribe(this.topic);
    }
    this.scpWebSocketService.offWithCallback(this.event, this.processUpdates);
  }


  protected init(): void {
    this.provider = this.game.provider.type.toLowerCase();
    this.providerIcon = `/assets/images/providers/${this.provider}.svg`;
    this.providerIcon2 = `/assets/images/providers/${this.provider}-2.svg`;
    const prefix: string = this.game.product === 'SLOTS' ? 'slot' : 'live';
    this.topic = `${prefix}-games/${this.game.gameId}`;
    this.event = `${prefix}-game-${this.game.gameId}-update`;
    this.subscribed = this.scpWebSocketService.subscribe(this.topic);
    this.scpWebSocketService.onWithCallback(this.event, this.processUpdates);
  }

  protected processUpdates = (data: string): void => {
    this.game = JSON.parse(data);
    this.gameChange.emit(this.game);
    this.prepareData();
    this.zone.run(() => this.changeDetectorRef.markForCheck());
  };

  protected prepareData(): void {
    const product: string = AbstractGameComponent.getSlug(this.game.product);
    const {
      routerLink,
      queryParams,
    } = this.gameUrlPipe.transform(product === 'slots' ? { slot: this.game } : { game: this.game }, this.route);

    this.routeLink = routerLink;
    this.queryParams = queryParams;
    this.rel = this.routeLink.endsWith('/launch') ? 'nofollow' : null;
    this.icon = this.game.icon?.url || `/assets/common/${product}/${this.game.provider.type.toLowerCase()}/${this.game.gameId}.jpg`;
    if (this.game.minStake) {
      this.stake = formatMoney({
        value: this.game.minStake * Math.pow(10, getNumberOfCurrencyDigits(this.currency)),
        currency: this.currency,
      }, true, false);
    } else {
      this.stake = null;
    }
  }

}
