import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sportId',
})
export class SportIdPipe implements PipeTransform {

  public transform(id: string) {
    return id.replace(/:/g, '-');
  }
}

