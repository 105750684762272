@if (banners?.length) {
  <div class='scp-banner'>
    <div class='swiper swiper-banners'>
      <div class='swiper-wrapper'>
        @for (banner of banners; track $index; ) {
          <div class='swiper-slide'>
            <picture class='scp-banner-picture'
                     [class.cursor]='banner.link || banner.info'
                     (click)='executeAction(banner)'>
              <source srcset='{{banner.desktop}}' media='(min-width: 801px)' width='1577' height='400' />
              <img [src]='banner.mobile'
                   [alt]='banner.alt'
                   [title]='banner.title'
                   width='800'
                   height='410'
                   [attr.loading]='$index === 0 ? "eager" : "lazy"' />
            </picture>
          </div>
        }
      </div>
    </div>
    @if (isMoreThanOneSlide) {
      <div class='swiper-pagination {{paginationClass}}'></div>
    }
  </div>
}
