<div class='scp-sport-events-swiper'>
  <div class='swiper'>
    <div class='swiper-wrapper'>
      @for (event of map.values(); track event.id; ) {
        <div class='swiper-slide'
             [class.swiper-hidden-slide]='$index >= itemsOnMobile'>
          <scp-sports-event-row [id]='event.id'
                                [event]='event'
                                [isBrowser]='isBrowser'
                                [mini]='true'
                                [tournament]='true'
                                [source]='"Top page"'>
          </scp-sports-event-row>
        </div>
      }
    </div>
  </div>
</div>
