import { Pipe, PipeTransform } from '@angular/core';
import { EventSport } from '@scpc/modules/sports/dto';

@Pipe({
  name: 'defaultCompetitorImage',
})
export class DefaultCompetitorImage implements PipeTransform {

  public transform(sport: EventSport) {
    return `/assets/common/sports/competitors/default-competitor-${sport.id.replace(/:/g, '-')}.png`;
  }

}

