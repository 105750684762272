import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  inject,
  Input,
  NgZone,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Event } from './../../dto';
import {
  EventSoccerLiveResultsComponent,
} from '@scpc/modules/sports/components/event-soccer-live-results/event-soccer-live-results.component';
import {
  EventBasketballLiveResultsComponent,
} from '@scpc/modules/sports/components/event-basketball-live-results/event-basketball-live-results.component';
import {
  EventTennisLiveResultsComponent,
} from '@scpc/modules/sports/components/event-tennis-live-results/event-tennis-live-results.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isTodayWithoutTime, isTomorrowWithoutTime } from '@scpc/utils/date.utils';
import { interval, Subscription } from 'rxjs';
import { delay, takeWhile } from 'rxjs/operators';
import { formatDate } from '@scpc/modules/lucky-numbers/utils/countdown';
import { CompetitorImagePipe } from '@scpc/modules/sports/pipes/competitor-image';
import {
  EventRugbyLiveResultsComponent,
} from '@scpc/modules/sports/components/event-rugby-live-results/event-rugby-live-results.component';
import {
  EventTableTennisLiveResultsComponent,
} from '@scpc/modules/sports/components/event-table-tennis-live-results/event-table-tennis-live-results.component';
import { SportIdPipe } from '@scpc/modules/sports/pipes/sport-id';
import { SportImagePipe } from '@scpc/modules/sports/pipes/sport-image';
import { PeriodPipe } from '@scpc/modules/sports/pipes/period.pipe';
import {
  EventVolleyballLiveResultsComponent,
} from '@scpc/modules/sports/components/event-volleyball-live-results/event-volleyball-live-results.component';
import {
  EventCricketLiveResultsComponent,
} from '@scpc/modules/sports/components/event-cricket-live-results/event-cricket-live-results.component';
import {
  EventHandballLiveResultsComponent,
} from '@scpc/modules/sports/components/event-handball-live-results/event-handball-live-results.component';
import {
  EventBasketball3x3LiveResultsComponent,
} from '@scpc/modules/sports/components/event-basketball-3x3-live-results/event-basketball-3x3-live-results.component';
import {
  EventIceHockeyLiveResultsComponent,
} from '@scpc/modules/sports/components/event-ice-hockey-live-results/event-ice-hockey-live-results.component';
import {
  EventBaseballLiveResultsComponent,
} from '@scpc/modules/sports/components/event-baseball-live-results/event-baseball-live-results.component';
import {
  EventAussieRulesLiveResultsComponent,
} from '@scpc/modules/sports/components/event-aussie-rules-live-results/event-aussie-rules-live-results.component';
import {
  EventAmericanFootballLiveResultsComponent,
} from '@scpc/modules/sports/components/event-american-football-live-results/event-american-football-live-results.component';
import {
  EventSnookerLiveResultsComponent,
} from '@scpc/modules/sports/components/event-snooker-live-results/event-snooker-live-results.component';
import {
  EventDartsLiveResultsComponent,
} from '@scpc/modules/sports/components/event-darts-live-results/event-darts-live-results.component';
import {
  EventFieldHockeyLiveResultsComponent,
} from '@scpc/modules/sports/components/event-field-hockey-live-results/event-field-hockey-live-results.component';
import {
  EventFutsalLiveResultsComponent,
} from '@scpc/modules/sports/components/event-futsal-live-results/event-futsal-live-results.component';
import {
  EventBadmintonLiveResultsComponent,
} from '@scpc/modules/sports/components/event-badminton-live-results/event-badminton-live-results.component';
import {
  EventBowlsLiveResultsComponent,
} from '@scpc/modules/sports/components/event-bowls-live-results/event-bowls-live-results.component';
import {
  EventBeachVolleyballLiveResultsComponent,
} from '@scpc/modules/sports/components/event-beach-volleyball-live-results/event-beach-volleyball-live-results.component';
import {
  EventSquashLiveResultsComponent,
} from '@scpc/modules/sports/components/event-squash-live-results/event-squash-live-results.component';
import {
  EventPadelLiveResultsComponent,
} from '@scpc/modules/sports/components/event-padel-live-results/event-padel-live-results.component';
import {
  EventCounterStrikeLiveResultsComponent,
} from '@scpc/modules/sports/components/event-counter-strike-live-results/event-counter-strike-live-results.component';
import {
  EventLeagueOfLegendsLiveResultsComponent,
} from '@scpc/modules/sports/components/event-league-of-legends-live-results/event-league-of-legends-live-results.component';
import {
  EventDotaLiveResultsComponent,
} from '@scpc/modules/sports/components/event-dota-live-results/event-dota-live-results.component';
import {
  EventMmaLiveResultsComponent,
} from '@scpc/modules/sports/components/event-mma-live-results/event-mma-live-results.component';
import { DefaultCompetitorImage } from '@scpc/modules/sports/pipes/default-competitor-image';
import { DefaultSeasonImage } from '@scpc/modules/sports/pipes/default-season-image';
import { ImgFallbackDirective } from '@scpc/modules/common/directives/img-fallback.derictive';
import {
  EventAlpineSkiingLiveResultsComponent,
} from '@scpc/modules/sports/components/event-alpine-skiing-live-results/event-alpine-skiing-live-results.component';
import {
  EventBiathlonLiveResultsComponent,
} from '@scpc/modules/sports/components/event-biathlon-live-results/event-biathlon-live-results.component';
import {
  EventFormula1LiveResultsComponent,
} from '@scpc/modules/sports/components/event-formula-1-live-results/event-formula-1-live-results.component';
import {
  EventFormulaELiveResultsComponent,
} from '@scpc/modules/sports/components/event-formula-e-live-results/event-formula-e-live-results.component';
import {
  EventMotoGpLiveResultsComponent,
} from '@scpc/modules/sports/components/event-moto-gp-live-results/event-moto-gp-live-results.component';
import {
  EventSkiJumpingLiveResultsComponent,
} from '@scpc/modules/sports/components/event-ski-jumping-live-results/event-ski-jumping-live-results.component';
import {
  EventGolfLiveResultsComponent,
} from '@scpc/modules/sports/components/event-golf-live-results/event-golf-live-results.component';
import { isEventEnded } from '@scpc/modules/sports/utils/event';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TournamentImagePipe } from '@scpc/modules/sports/pipes/tournament-image';
import {
  EventNascarLiveResultsComponent,
} from '@scpc/modules/sports/components/event-nascar-live-results/event-nascar-live-results.component';
import {
  EventWaterpoloLiveResultsComponent,
} from '@scpc/modules/sports/components/event-waterpolo-live-results/event-waterpolo-live-results.component';

@Component({
  selector: 'scp-sports-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DatePipe,
    TranslateModule,
    ImgFallbackDirective,
    SportIdPipe,
    SportImagePipe,
    CompetitorImagePipe,
    DefaultCompetitorImage,
    DefaultSeasonImage,
    PeriodPipe,
    EventSoccerLiveResultsComponent,
    EventBasketballLiveResultsComponent,
    EventTennisLiveResultsComponent,
    EventRugbyLiveResultsComponent,
    EventTableTennisLiveResultsComponent,
    EventVolleyballLiveResultsComponent,
    EventCricketLiveResultsComponent,
    EventHandballLiveResultsComponent,
    EventBasketball3x3LiveResultsComponent,
    EventIceHockeyLiveResultsComponent,
    EventBaseballLiveResultsComponent,
    EventAussieRulesLiveResultsComponent,
    EventAmericanFootballLiveResultsComponent,
    EventSnookerLiveResultsComponent,
    EventDartsLiveResultsComponent,
    EventFieldHockeyLiveResultsComponent,
    EventFutsalLiveResultsComponent,
    EventBadmintonLiveResultsComponent,
    EventBowlsLiveResultsComponent,
    EventBeachVolleyballLiveResultsComponent,
    EventSquashLiveResultsComponent,
    EventPadelLiveResultsComponent,
    EventCounterStrikeLiveResultsComponent,
    EventLeagueOfLegendsLiveResultsComponent,
    EventDotaLiveResultsComponent,
    EventMmaLiveResultsComponent,
    EventAlpineSkiingLiveResultsComponent,
    EventBiathlonLiveResultsComponent,
    EventFormula1LiveResultsComponent,
    EventFormulaELiveResultsComponent,
    EventMotoGpLiveResultsComponent,
    EventSkiJumpingLiveResultsComponent,
    EventGolfLiveResultsComponent,
    EventNascarLiveResultsComponent,
    EventWaterpoloLiveResultsComponent,
    TournamentImagePipe,
  ],
})
export class EventCardComponent implements AfterViewInit, OnChanges {

  @Input()
  public event: Event;

  @Input()
  public isBrowser = true;

  @Input()
  public background = false;

  @Input()
  public responsive = true;

  @Input()
  public sport: boolean = true;

  @Input()
  public markets = true;

  @Input()
  public tracker = true;

  @ViewChildren('countdown', { read: ElementRef })
  private countdown: QueryList<ElementRef<HTMLDivElement>>;

  @ViewChildren('countdownLabel', { read: ElementRef })
  private countdownLabel: QueryList<ElementRef<HTMLDivElement>>;

  protected site: string;

  private readonly datePipe: DatePipe = new DatePipe('en');
  private readonly dateFormat: string = 'dd-MM-yyyy';
  private readonly timeFormat: string = 'HH:mm';
  private readonly TODAY_AT = this.translateService.instant('S.TODAY_AT');
  private readonly TOMORROW_AT = this.translateService.instant('S.TOMORROW_AT');
  private readonly TODAY_IN = this.translateService.instant('S.TODAY_IN');
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private subscription: Subscription;

  constructor(private readonly zone: NgZone,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly translateService: TranslateService,
              @Inject('HOST') private readonly host: string) {
    this.site = this.host.split('//')[1];
  }

  public ngAfterViewInit() {
    this.runTimer();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    /* istanbul ignore next */
    if ('event' in changes) {
      const { firstChange, currentValue, previousValue } = changes.event;
      if (!firstChange && (isEventEnded(currentValue) || (!currentValue.live && currentValue.scheduleTime !== previousValue.scheduleTime))) {
        this.changeDetectorRef.detectChanges();
        this.updateDate(this.event.scheduleTime);
      }
    }
  }

  private runTimer(): void {
    /* istanbul ignore else */
    if (!this.event.live) {
      this.zone.runOutsideAngular((): void => {
        if (this.isBrowser) {
          this.subscription?.unsubscribe();
          const eventDate: number = this.event.scheduleTime;
          const now: number = new Date().getTime();
          const startTimerTime: number = eventDate - 60 * 60 * 1000;
          const timerDelay: number = startTimerTime - now;
          if (startTimerTime <= now) {
            this.updateDate(eventDate);
          } else {
            this.showStartDate(eventDate);
          }
          if (timerDelay >= 3 * 24 * 60 * 60 * 1000) {
            return;
          }
          /* istanbul ignore next */
          this.subscription = interval(1000).pipe(
            delay(Math.max(0, timerDelay)),
            takeWhile(() => eventDate > new Date().getTime()),
            takeUntilDestroyed(this.destroyRef),
          ).subscribe({
            next: () => this.updateDate(eventDate),
            complete: () => this.showStartDate(eventDate),
          });
        } else {
          this.countdown.forEach(e => e.nativeElement.textContent = ' ');
          this.countdownLabel.forEach(e => e.nativeElement.textContent = ' ');
        }
      });
    } else if (this.event.status === 'Ended' || this.event.status === 'Finished') {
      this.updateDate(this.event.scheduleTime);
    }
  }

  private showStartDate(eventDate: number) {
    if (isTodayWithoutTime(eventDate)) {
      this.countdownLabel.forEach(e => e.nativeElement.textContent = this.TODAY_AT);
    } else if (isTomorrowWithoutTime(eventDate)) {
      this.countdownLabel.forEach(e => e.nativeElement.textContent = this.TOMORROW_AT);
    } else {
      this.countdownLabel.forEach(e => e.nativeElement.textContent = this.datePipe.transform(eventDate, this.dateFormat) + ' ');
    }
    this.countdown.forEach(e => {
      e.nativeElement.classList.remove('timer');
      e.nativeElement.textContent = this.datePipe.transform(eventDate, this.timeFormat);
    });
  }

  private updateDate(date: number, formatStr: string = 'HH:mm:ss'): void {
    const diff: number = date - new Date().getTime();
    if (diff <= 1000) {
      this.showStartDate(date);
    } else {
      this.countdownLabel.forEach(e => e.nativeElement.textContent = this.TODAY_IN);
      this.countdown.forEach(e => {
        if (!e.nativeElement.classList.contains('timer')) {
          e.nativeElement.classList.add('timer');
        }
        e.nativeElement.textContent = formatDate({ date: diff, formatStr });
      });
    }
  }

}
