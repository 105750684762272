import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CmsService } from '@scpc/modules/common/services/cms.service';
import { LobbyItem } from '@scpc/dto/lobby';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LobbyResolver implements Resolve<{ items?: LobbyItem[]; error?: HttpErrorResponse }> {

  constructor(private readonly cmsService: CmsService) {
  }

  public resolve(): Observable<{ items?: LobbyItem[]; error?: HttpErrorResponse }> {
    return this.cmsService.getLobby()
      .pipe(map(items => ({
        items: items.map(item => {
          if (item.type === 'LIVE_GAMES_ITEM' && !item.link.url.startsWith('/')) {
            item.link.url = '/live-games' + (item.link.url ? `/categories/${item.link.url}` : '');
          } else if (item.type === 'LIVE_GAMES_CATEGORY_ITEM' && !item.category.url.startsWith('/')) {
            item.category.url = '/live-games' + (item.category.url ? `/categories/${item.category.url}` : '');
          } else if (item.type === 'SLOT_GAMES_ITEM' && !item.link.url.startsWith('/')) {
            item.link.url = '/slots' + (item.link.url ? `/categories/${item.link.url}` : '');
          } else if (item.type === 'SLOT_GAMES_CATEGORY_ITEM' && !item.category.url.startsWith('/')) {
            item.category.url = '/slots' + (item.category.url ? `/categories/${item.category.url}` : '');
          } else if (item.type === 'LUCKY_NUMBERS_POPULAR_ITEM') {
            item.link.url = '/lucky-numbers/popular';
          } else if (item.type === 'LUCKY_NUMBERS_RAPID_ITEM') {
            item.link.url = '/lucky-numbers/rapid';
          } else if (item.type === 'RECOMMENDED_GAMES_ITEM' && item.link.product === 'SLOTS' && item.link && !item.link.url.startsWith('/')) {
            item.link.url = '/slots' + (item.link.url ? `/categories/${item.link.url}` : '');
          } else if (item.type === 'RECOMMENDED_GAMES_ITEM' && item.link.product === 'LIVE_GAMES' && item.link && !item.link.url.startsWith('/')) {
            item.link.url = '/live-games' + (item.link.url ? `/categories/${item.link.url}` : '');
          }
          return item;
        }),
      })))
      .pipe(catchError(error => of({ error })));
  }

}
