import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { AsyncPipe, isPlatformBrowser, NgStyle } from '@angular/common';
import { LobbyItemComponent } from '@scpc/modules/lobby/components/lobby-item/lobby-item.component';
import { BannerComponent } from '@scpc/modules/common/components/banners/components/banner/banner.component';
import { GamesSwiperComponent } from '@scpc/modules/games-lobby/components/games-swiper/games-swiper.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { GameIconComponent } from '@scpc/modules/games-lobby/components/game-icon/game-icon.component';
import { AuthenticationService, StorageService } from '@scpc/modules/common/services';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { ScpWebSocketService } from '@scpc/modules/common/services/scp.websocket.service';
import { DrawType } from '@scpc/modules/lucky-numbers/pages/pageable.component';
import { LobbyItem, LobbyItemType } from '@scpc/dto/lobby';
import { LuckyNumbersService } from '@scpc/modules/lucky-numbers/services/lucky-numbers.service';
import { CacheService } from '@scpc/modules/common/services/cache.service';
import { applyUpdateAndGetDraws, filterDraws, getSortDiff } from '@scpc/modules/lobby/utils/utils';
import { Observable } from 'rxjs';
import {
  LobbyItemBonusesComponent,
} from '@scpc/modules/lobby/components/lobby-item-bonuses/lobby-item-bonuses.component';
import {
  LobbyItemSportEventsComponent,
} from '@scpc/modules/lobby/components/lobby-item-sport-events/lobby-item-sport-events.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TopWinnersComponent } from '@scpc/modules/common/components/top-winners/top-winners.component';
import {
  LobbyItemLuckyNumbersDrawsComponent,
} from '@scpc/modules/lobby/components/lobby-item-lucky-numbers-draws/lobby-item-lucky-numbers-draws.component';

@Component({
  selector: 'scp-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle,
    AsyncPipe,
    RouterLink,
    LobbyItemComponent,
    BannerComponent,
    GamesSwiperComponent,
    GameIconComponent,
    LobbyItemBonusesComponent,
    LobbyItemSportEventsComponent,
    TopWinnersComponent,
    LobbyItemLuckyNumbersDrawsComponent,
  ],
})
export class LobbyComponent implements OnInit, OnDestroy {

  private static readonly TOPIC = 'lucky-numbers/draws?type=';
  private static readonly DRAWS_ITEMS_TYPES = [
    'LUCKY_NUMBERS_POPULAR_ITEM',
    'LUCKY_NUMBERS_RAPID_ITEM',
  ];

  protected drawsLobby: LobbyItem[];
  protected lobby: LobbyItem[];
  protected isAuthorized: boolean;
  protected currency = this.storageService.getCurrency();
  protected readonly running = new Map<LobbyItem, boolean>;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private readonly scpWebSocketService: ScpWebSocketService,
              private readonly luckyNumbersService: LuckyNumbersService,
              private readonly activatedRoute: ActivatedRoute,
              protected readonly storageService: StorageService,
              private readonly authenticationService: AuthenticationService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly zone: NgZone,
              private readonly cacheService: CacheService,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
  }

  public ngOnInit() {
    this.lobby = this.activatedRoute.snapshot.data.lobby?.items || [];
    this.drawsLobby = this.lobby.filter((item: LobbyItem): boolean => LobbyComponent.DRAWS_ITEMS_TYPES.includes(item.type));
    this.drawsLobby.forEach(item => item.draws.sort((a: Draw, b: Draw) => getSortDiff(a, b)));
    this.authenticationService.isAuthorized().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.updateAuthorization(value));
    this.authenticationService.authorization.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.updateAuthorization(value));

    if (isPlatformBrowser(this.platformId) && this.drawsLobby.length) {
      this.scpWebSocketService.subscribe(`${LobbyComponent.TOPIC}${DrawType.REGULAR}`);
      this.scpWebSocketService.subscribe(`${LobbyComponent.TOPIC}${DrawType.INSTANT}`);

      this.scpWebSocketService
        .on(`${DrawType.REGULAR.toLowerCase()}-draws-update`)
        .subscribe((draw: Draw) => this.processDrawUpdate(draw));

      this.scpWebSocketService
        .on(`${DrawType.INSTANT.toLowerCase()}-draws-update`)
        .subscribe((draw: Draw) => this.processDrawUpdate(draw));
    }
  }

  public ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.scpWebSocketService.unsubscribe(`${LobbyComponent.TOPIC}${DrawType.REGULAR}`);
      this.scpWebSocketService.unsubscribe(`${LobbyComponent.TOPIC}${DrawType.INSTANT}`);
    }
  }

  protected trackLobbyByFn(index: number, item: LobbyItem): string {
    return item.type + index + item.title;
  }

  protected refreshDraws(item: LobbyItem) {
    if (!this.running.get(item)) {
      this.running.set(item, true);
      this.getDraws(item).subscribe({
        next: (draws: Draw[]): void => {
          item.draws = filterDraws(item, draws);
          this.changeDetectorRef.markForCheck();
          this.running.set(item, false);
        },
        complete: () => this.running.set(item, false),
      });
    }
  }

  protected remove(item: LobbyItem): void {
    this.lobby.splice(this.lobby.indexOf(item), 1);
  }

  private updateAuthorization(value: boolean) {
    this.isAuthorized = value;
    this.changeDetectorRef.markForCheck();
  }

  /* istanbul ignore next */
  private processDrawUpdate(draw: Draw): void {
    for (const item of this.drawsLobby) {
      if (item.type === LobbyItemType.LUCKY_NUMBERS_POPULAR_ITEM && !item.drawsIds.includes(draw.drawId)) {
        continue;
      } else if (item.type === LobbyItemType.LUCKY_NUMBERS_RAPID_ITEM && draw.type !== 'INSTANT') {
        continue;
      }
      this.getDraws(item).subscribe(draws => {
        item.draws = applyUpdateAndGetDraws(item, draws, draw);
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private getDraws(item: LobbyItem): Observable<Draw[]> {
    return this.cacheService.get(item.type + this.drawsLobby.indexOf(item),
      this.luckyNumbersService.getDraws(item.type === LobbyItemType.LUCKY_NUMBERS_POPULAR_ITEM ? DrawType.POPULAR : DrawType.INSTANT));
  }

}
