@if (placeholder) {
  <div class='scp-game-icon-placeholder'>
    <div class='scp-game-icon-placeholder__animated-background ssc-square'></div>
  </div>
} @else {
  <a [routerLink]='routeLink' [queryParams]='queryParams' [state]='{game: game}' class='scp-game-icon' [rel]='rel'
     [class.closed]='game.closed'
     [class.small]='small'>
    <img [src]='icon' [alt]='game.name + " game icon"' loading='lazy'>
    @if (game.closed) {
      <div class='scp-game-icon__closed' [class.small]='small'>{{ 'Game is unavailable' | translate }}</div>
    }
    @if (!simple) {
      @if (stake) {
        <div class='scp-game-icon__min-skate'>{{ stake }}</div>
      }
      <!-- @if (game.label) {
         <div class='scp-game-icon__label'>
           <div class='scp-game-icon__label__custom'>
             <img [src]='game.label.url' alt=''>
           </div>
         </div>
       } @else if (game.new) {
         <div class='scp-game-icon__label'>
           <div class='scp-game-icon__label__new'>
             <div>NEW</div>
           </div>
         </div>
       }-->
      @if (!game.closed && game.numberOfPlaces) {
        <div class='scp-game-icon__places'>
          <img alt='' src='/assets/images/svg/players.svg' height='14' width='14'>
          {{ game.numberOfUsedPlaces || 0 }}/{{ game.numberOfPlaces }}
        </div>
      }
      <div class='scp-game-icon__background'>
        <img [src]='icon' alt='' loading='lazy'>
      </div>
      <div class='scp-game-icon__name' [class.small]='small' [class.closed]='game.closed'>
        @if (providerIcon) {
          <img [src]='providerIcon' alt='' loading='lazy' width='24' [class]='provider'>
        }
        <div>{{ game.name }}</div>
      </div>
    }
  </a>
}
