import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input, Output,
} from '@angular/core';
import { Swiper } from 'swiper';
import { FreeMode, Grid } from 'swiper/modules';
import { AbstractSwiperComponent } from '@scpc/modules/lobby/components/abstract.swiper.component';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { DrawItemComponent } from '@scpc/modules/lucky-numbers/components/draw-item/draw-item.component';

@Component({
  selector: 'scp-lobby-item-lucky-numbers-draws',
  templateUrl: './lobby-item-lucky-numbers-draws.component.html',
  styleUrls: ['./lobby-item-lucky-numbers-draws.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DrawItemComponent],
})
export class LobbyItemLuckyNumbersDrawsComponent extends AbstractSwiperComponent implements AfterContentInit {

  @Input()
  public draws: Draw[];

  @Input()
  public itemsOnMobile: number = Number.MAX_VALUE;

  @Output()
  protected reload: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly elementRef: ElementRef<HTMLDivElement>) {
    super();
  }

  public ngAfterContentInit(): void {
    this.idle.requestIdleCallback((): void => {
      this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('.swiper') as HTMLElement, {
        init: this.isBrowser,
        modules: [FreeMode, Grid],
        freeMode: true,
        touchRatio: 1.5,
        speed: 400,
        resistance: true,
        resistanceRatio: 0.5,
        slidesOffsetBefore: 15,
        slidesOffsetAfter: 15,
        slidesPerView: 'auto',
        resizeObserver: true,
        updateOnWindowResize: true,
        watchOverflow: true,
        breakpoints: {
          '0': {
            slidesPerView: 1,
            slidesPerGroup: 1,
            grid: {
              rows: 100,
              fill: 'row',
            },
            spaceBetween: 0,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          '441': {
            slidesPerView: 'auto',
            grid: undefined,
            spaceBetween: 0,
            slidesOffsetBefore: 15,
            slidesOffsetAfter: 15,
          },
        },
        on: { ...this.on },
      });
    });
  }

  protected trackDrawByFn(draw: Draw): string {
    return draw.drawId + draw.drawDate + draw.active;
  }

}
