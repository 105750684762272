import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'scp-lobby-item',
  templateUrl: './lobby-item.component.html',
  styleUrls: ['./lobby-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink],
})
export class LobbyItemComponent implements OnChanges {

  @Input()
  public template: TemplateRef<unknown>;

  @Input()
  public title: string;

  @Input()
  public isAuthorized = false;

  @Input()
  public onlyForAuthorized = false;

  @Input()
  public navigation = true;

  @Input()
  public beginning = true;

  @Input()
  public end = true;

  @Output()
  public prev: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public next: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public link?: {
    title: string,
    url: string
  };
  protected routeLink: string;
  protected queryParams: object;


  constructor(private router: Router) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('link' in changes) {
      if (this.link) {
        const parts = this.link.url.split('?');
        this.routeLink = parts[0];
        if (parts[1]) {
          this.queryParams = this.router.parseUrl('?' + parts[1]).queryParams;
        }
      } else {
        this.routeLink = undefined;
        this.queryParams = undefined;
      }
    }
  }

}
