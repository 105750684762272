import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { catchError, createPreloadLink } from '@scpc/utils/dom.utils';
import { Swiper } from 'swiper';
import { PaginationOptions, AutoplayOptions } from 'swiper/types';
import { Autoplay, Manipulation, Pagination } from 'swiper/modules';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';
import { Banner } from '@scpc/modules/common/components/banners/models';

@Component({
  selector: 'scp-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BannerComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit {

  @Input()
  public isAuthorized: boolean;

  @Input()
  public slides: Banner[];

  @Input()
  public isFTD = false;

  protected banners: Banner[];
  protected isMoreThanOneSlide: boolean;
  protected isBrowser: boolean;
  protected paginationClass = 'swiper-pagination-' + Math.round(Math.random() * 100_000).toString();
  private dialogRef: DialogRef<any>;
  private swiper: Swiper;
  private readonly destroy: Subject<void> = new Subject<void>();
  private afterContentInit = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: Dialog,
    private readonly idle: Idle,
    private readonly zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Optional() @Inject('USER_AGENT') public readonly userAgent: string,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly elementRef: ElementRef<HTMLDivElement>,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public ngOnInit(): void {
    this.updateBanners();
    if (this.banners?.length) {
      /* istanbul ignore next */
      const before = this.document.head.querySelector('#font-3')?.nextSibling;
      this.document.head.insertBefore(createPreloadLink(this.document, this.banners[0].mobile, '(max-width: 800px)'), before);
      this.document.head.insertBefore(createPreloadLink(this.document, this.banners[0].desktop, '(min-width: 801px)'), before);
    }

    this.router.events
      .pipe(
        takeUntil(this.destroy),
        filter((event: NavigationStart) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef),
      )
      .subscribe( /* istanbul ignore next */ () => this.dialogRef.close());

    this.router.events.pipe(
      takeUntil(this.destroy),
      filter((event: NavigationEnd) => event instanceof NavigationEnd),
    ).subscribe(() => this.updateBanners(true));
  }

  public ngOnChanges(changes: SimpleChanges) {
    /* istanbul ignore next */
    if (('isAuthorized' in changes || 'isFTD' in changes) && this.afterContentInit) {
      this.updateBanners(true, true);
    }
  }

  public ngAfterContentInit() {
    this.createOrUpdateSwiper();
    this.afterContentInit = true;
  }

  public ngOnDestroy() {
    this.dialogRef?.close();
    catchError(() => this.swiper?.destroy());
    this.destroy.next();
    this.destroy.complete();
  }

  public async executeAction(banner: Banner) {
    if (banner.info) {
      await this.showInfo(banner);
    } else if (banner.link) {
      await this.navigate(banner);
    }
  }

  private async navigate(banner: Banner) {
    /* istanbul ignore if */
    if (banner.link.startsWith('http')) {
      window.open(banner.link, '_blank').focus();
    } else {
      await this.zone.run(async () => this.router.navigateByUrl(banner.link));
    }
  }

  private async showInfo(banner: Banner) {
    this.toggleSwiper(false);
    await import('../banner-info/banner-info.component').then(async (component): Promise<void> => {
      this.dialogRef = this.dialog.open(component.BannerInfoComponent, {
        data: banner,
        panelClass: [
          'scp-filter-without-width',
        ],
        backdropClass: 'scp-backdrop',
        minHeight: '0px',
        maxHeight: '80vh',
        maxWidth: (banner.info.width || 900) + 'px',
      }).updatePosition();
      this.dialogRef.closed.subscribe(() => this.toggleSwiper(true));
    });
  }

  private updateBanners(detectChanges: boolean = false, immediately: boolean = false) {
    this.banners = this.getBanners(this.activatedRoute);
    this.isMoreThanOneSlide = this.banners?.length > 1;
    if (detectChanges) {
      this.zone.run(() => this.changeDetectorRef.detectChanges());
      this.createOrUpdateSwiper(immediately);
    }
  }

  private getBanners(route: ActivatedRoute) {
    if (this.slides?.length) {
      return this.filterBanners(this.slides);
    }
    do {
      if (!route) {
        return null;
      }
      const banners = route.snapshot.data.seo?.banners;
      if (banners) {
        return this.filterBanners(banners);
      }
      route = route.firstChild;
      // eslint-disable-next-line no-constant-condition
    } while (true);
  }

  private filterBanners(banners: Banner[]) {
    return banners.filter((banner: Banner): boolean => {
      switch (banner.visibleFor) {
        case 'ALL': {
          return true;
        }
        case 'ONLY_NOT_AUTHORIZED': {
          return !this.isAuthorized;
        }
        case 'ONLY_AUTHORIZED': {
          return this.isAuthorized;
        }
        case 'ONLY_AUTHORIZED_WITH_FTD': {
          return this.isAuthorized && this.isFTD;
        }
        case 'ONLY_AUTHORIZED_WITHOUT_FTD': {
          return this.isAuthorized && !this.isFTD;
        }
        default: {
          return false;
        }
      }
    });
  }

  private createOrUpdateSwiper(immediately: boolean = false) {
    const loop: boolean = this.isBrowser && this.isMoreThanOneSlide;
    const autoplay: AutoplayOptions | boolean = loop ? { delay: 20000, disableOnInteraction: false } : false;
    const pagination: PaginationOptions | boolean = loop ? {
      clickable: true,
      type: 'bullets',
      el: '.' + this.paginationClass,
      bulletActiveClass: 'swiper-pagination-bullet-active',
      lockClass: 'swiper-pagination-lock',
    } : false;
    if (this.banners?.length) {
      /* istanbul ignore next */
      if (this.swiper?.params && !immediately) {
        this.swiper.params.loop = loop;
        this.swiper.params.autoplay = autoplay;
        this.swiper.params.pagination = pagination;
        this.swiper.update();
      } else {
        const callback = () => {
          /* istanbul ignore next */
          this.swiper?.destroy();
          setTimeout(() => {
            this.swiper = null;
            this.zone.runOutsideAngular(() => {
              this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('.swiper-banners') as HTMLElement, {
                init: this.isBrowser,
                initialSlide: 0,
                modules: [Pagination, Autoplay, Manipulation],
                loop,
                spaceBetween: 5,
                userAgent: this.userAgent,
                autoplay,
                pagination,
                on: {
                  slideChangeTransitionEnd: (swiper: Swiper) => {
                    /* istanbul ignore next */
                    if (swiper.slides[swiper.activeIndex]?.classList.contains('swiper-slide-duplicate')) {
                      swiper.slideToLoop(swiper.realIndex, 0);
                    }
                  },
                },
              });
            });
          });
          this.changeDetectorRef.markForCheck();
        };
        if (immediately || !this.isBrowser) {
          callback();
        } else {
          this.idle.requestIdleCallback(callback);
        }
      }
    } else {
      /* istanbul ignore if */
      if (this.swiper) {
        catchError(() => this.swiper.destroy(true, true));
        this.swiper = null;
      }
    }
  }

  private toggleSwiper(enabled: boolean) {
    /* istanbul ignore else */
    if (this.swiper && this.swiper.autoplay) {
      /* istanbul ignore else */
      if (enabled) {
        this.swiper.autoplay.start();
      } else {
        this.swiper.autoplay.stop();
      }
    }
  }

}
